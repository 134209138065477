:root{
    --color-blue : #5F2282;
    --color-2 : #E60073;
    --white:#ffffff;
    --black:#000000;
}


body {
    background: linear-gradient(0deg, var(--color-blue), var(--white)) !important;
}

.form-error {
    color: #a76161;
    text-align: center;
    margin-bottom: 10px;
}

.form-error1 {
    color: #a76161;
}

.login-box-body {
    box-shadow: 0 0px 30px var(--color-2);
}

.btn-primary{
    background: #2A2F4F !important;
    border: none;
    transform: scale(0.95);
    transition: all 0.5s;
}
.btn-primary:hover{
    transform: scale(1);
}

.card {
    padding: 2em;
  }
  
  .read-the-docs {
    color: #888;
  }
  .otpInput{
    width: 40px;
    height: 40px;
    margin: 5px;
    text-align: center;
    font-size: 1.2em;
  }
/* .page-item {
    background-color: red;
} */
